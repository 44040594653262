import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import BackgroundSplitContainer from "../../components/BackgroundSplitContainer";
import Navigator from "../../components/Navigator";
import CompanyTitle from "../../components/CompanyTitle";
import CompanySubtitle from "../../components/CompanySubtitle";
import CompanyAbout from "../../components/CompanyAbout";
import ViewSolutions from "../../components/ViewSolutions";

export default function Company() {
  // const handleConversion = () => {
  //   window.gtag!('config', 'AW-16851339240');
  //   window.gtag!('event', 'conversion', {'send_to': 'AW-16851339240/aLvICKXQ4JoaEOiPrOM-'});
  // }

  return (
    <div className="Company flex flex-col h-full">
      <Header selectedId="company" device="desktop"/>

      <BackgroundSplitContainer 
        imgUrl="https://upcdn.io/12a1z8V/raw/abeventos/images/longer-back-rect.png"
        width={80}
        className="flex flex-col"
      >
        <CompanyTitle />
        <CompanySubtitle />
        <CompanyAbout />

        <div className="flex gap-x-4 mt-5 xl:mt-10 2xl:mt-0">
          <a 
            href="/contato" 
            className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] w-44 p-3 text-xs font-black rounded-md mt-2 flex gap-x-2 items-center"
            // onClick={handleConversion}
          >
            <img className="h-4" src="https://upcdn.io/12a1z8V/raw/abeventos/images/coin.png" alt="vetor de moedas" />
            Solicite um orçamento
          </a>

          <ViewSolutions />
        </div>
      
      <Navigator selectedId="company"/>
      </BackgroundSplitContainer>
    </div>
  );
}